import React, { FC, useContext, useEffect, useState } from "react";
import { Table, Tag } from "antd";
import type { Breakpoint, TableProps } from "antd";
import styled from "styled-components";
import { useStore, useWordsStore } from "../../state/stores/store";
import { WordDocument } from "../../consts/wordDocument.type";
import {
  capitalizeFirstLetter,
  formatJlptList,
  formatJlptWord,
} from "../../utils/stringUtil";
import { useResponsive } from "../../utils/useResponsive";

type ColumnsType<T extends object> = TableProps<T>["columns"];
type TablePagination<T extends object> = NonNullable<
  Exclude<TableProps<T>["pagination"], boolean>
>;
type TablePaginationPosition = NonNullable<
  TablePagination<any>["position"]
>[number];

interface VocabListProps {}

export interface DataType {
  key: string;
  kanji: string;
  reading: string;
  english: string;
  jlpt: string;
  tags?: string[];
}

const capitalize = (s: string) => {
  if (!s) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const renderEng = (eng: string) => {
  return eng
    .replace(/[\[\]']/g, "")
    .split(", ")
    .map((word) => capitalize(word))
    .join(", ");
};

interface KanjiFontProps {
  width?: number;
}

const KanjiFont = styled.span<KanjiFontProps>`
  font-size: ${(props) => (props.width && props.width > 600 ? "20px" : "15px")};
`;

const getTags = (isCommon = false, categories: Array<string>) => {
  let tags = categories.map((category: string) => category);
  if (isCommon) tags.unshift("Common");
  return tags;
};

const VocabList: FC<VocabListProps> = (props: any) => {

  // Define your breakpoints
  // const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // const isTablet = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  // const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  
  // console.log({)

  const {isMobile, isTablet, isDesktop} = useResponsive()

  const setIsInfoModalOpen = useStore((state) => state.setIsInfoModalOpen);
  const setSelectedWord = useStore((state) => state.setSelectedWord);
  const loading = useStore((state) => state.loading);


  // const { isMobile, isTablet, isDesktop } = useContext(ResponsiveContext);

  const {} = props;

  const words = useStore((state) => state.displayedWords);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [top, setTop] = useState<TablePaginationPosition>("topLeft");
  const [bottom, setBottom] = useState<TablePaginationPosition>("bottomRight");
  const [openedRowData, setOpenRowData] = useState({});

  const breakpoint: Breakpoint = "lg";

  const columns = [
    {
      title: "Kanji",
      dataIndex: "kanji",
      key: "kanji",
      width: "8%",
      render: (text: string) => <KanjiFont>{text}</KanjiFont>,
      // responsive: ["sm"],
    },
    {
      title: "Reading",
      dataIndex: "reading",
      key: "reading",
      width: "8%",
    },
    {
      title: "Translations",
      dataIndex: "english_definitions",
      key: "english_definitions",
      width: "45%",
      render: (arr: Array<string>) => {
        return arr
          .map((sentence: string) => capitalizeFirstLetter(sentence))
          .join(", ");
      },
    },
    {
      title: "JLPT",
      dataIndex: "jlpt",
      key: "jlpt",
      render: (arr: Array<string>) => {
        console.log({ arr });
        return formatJlptList(arr);
      },
      filters: [
        { text: "N5", value: "N5" },
        { text: "N4", value: "N4" },
        { text: "N3", value: "N3" },
        { text: "N2", value: "N2" },
        { text: "N1", value: "N1" },
        { text: "N1+", value: "N1+" },
      ],
      onFilter: (value: any, record: any) => {
        if (value == "N1+" && record.jlpt.length == 0) {
          return true;
        }
        if (record.jlpt.length > 0) {
          return formatJlptWord(record.jlpt[0]) === value;
        }
        return false;
      },
      // responsive: "lg",
      width: "1%",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      responsive: [breakpoint],
      //   responsive: ["lg"],
      render: (tags: any) => (
        <span>
          {(tags ?? []).map((tag: any) => {
            if (tag === "NONE" || tag === "False") return <></>;
            if (tag === "Common")
              return (
                <Tag key={tag} color="green">
                  COMMON
                </Tag>
              );
            return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
          })}
        </span>
      ),
    },
  ];

  const getData = () => {
    return words
      .filter((wordDoc: WordDocument) => {
        const { categories, word, word_data } = wordDoc;
        const { english_definitions, is_common, jlpt, reading, tags } =
          word_data;

        if (reading && english_definitions) return wordDoc;
      })
      .map((wordDoc: WordDocument) => {
        const { categories, word, word_data } = wordDoc;
        const { english_definitions, is_common, jlpt, reading, tags } =
          word_data;
        return {
          kanji: word,
          reading,
          english_definitions,
          jlpt: jlpt ?? "N1+",
          tags: getTags(is_common, categories),
          wordDoc,
        };
      });
  };

  return (
    <Table
      loading={loading}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            setIsInfoModalOpen(true);
            setSelectedWord(record.wordDoc);
            // console.log({ record });
          }, // click row
          onDoubleClick: (event) => {}, // double click row
          onContextMenu: (event) => {}, // right button click row
          onMouseEnter: (event) => {}, // mouse enter row
          onMouseLeave: (event) => {}, // mouse leave row
        };
      }}
      dataSource={getData()}
      columns={columns}
      pagination={{pageSize: isMobile ? 6  : isTablet  ? 7 : 10, position: isDesktop ? ["bottomCenter"] :["topCenter", "bottomCenter"], simple: isMobile}}
    />
  );
};

export default VocabList;
