import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePapaParse } from 'react-papaparse';
import {
  Breadcrumb,
  Button,
  Layout,
  Menu,
  Drawer,
  Grid,
  theme,
  MenuProps,
  Input,
  Space,
} from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import { AudioOutlined, MenuOutlined } from '@ant-design/icons';
import { useC1Store, useC2Store, useStore } from '../../state/stores/store';
import { Category } from '../../consts/category.type';
import { getWordsByCategory } from '../../api/axios/words';
import InfoModal from '../molecule/infoModal';
import Lookup from './lookup';
import CategoryPage from './categoryPage';
import SearchKanji from '../organism/searchKanji';
import PhotoUpload from '../organism/photoUpload';

import { useResponsive } from '../../utils/useResponsive';
import CharacterRecognition from './characterRecognition';

const { Header } = Layout;
const { useBreakpoint } = Grid;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1677ff',
    }}
  />
);

const testData = require('../../testData.json');

const AllButton = styled(Button)`
  margin-right: 8px;
`;

const Logo = styled.span`
  font-family: 'Seymour One', sans-serif;
  font-style: bold;
  font-size: 40px;
`;

const MenuItemLabelText = styled.span``;

const menuItems = ['Home', 'Kanji Lookup', 'Handwriting Analyzer'];

const items1: MenuProps['items'] = menuItems.map((key) => ({
  key,
  label: key,
}));

const Home: React.FC = () => {
  const state = useStore((state) => state);
  const c1 = useC1Store((state) => state.category);
  const c2 = useC2Store((state) => state.category);
  const updateWords = useStore((state) => state.updateWords);
  const breadcrumbs = useStore((state) => state.breadcrumbs);
  const setBreadcrumbs = useStore((state) => state.setBreadcrumbs);
  const setDisplayableWords = useStore((state) => state.setDisplayedWords);
  const loadedIds = useStore((state) => state.loadedIds);
  const addLoadedCategoryIds = useStore((state) => state.addLoadedCategoryIds);
  const loadedCategoryIds = useStore((state) => state.loadedCategoryIds);

  const [selectedKey, setSelectedKey] = useState<string>('Home');
  const { readString } = usePapaParse();
  const [search, setSearch] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setSearch(value);
    filterBySearch();
  };

  const onMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedKey(e.key as string);
    // If mobile, close drawer after clicking a menu item
    setDrawerVisible(false);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case menuItems[0]:
        return <CategoryPage />;
      case menuItems[1]:
        return <SearchKanji />;
      case menuItems[2]:
        // return <PhotoUpload />;
        return <CharacterRecognition />
      default:
        return null;
    }
  };

  const filterBySearch = () => {
    const filteredWords = words.filter((wordRow: any) => {
      if (!wordRow.join(' ').includes(search)) return false;
      wordRow[1] = (wordRow[1] ?? '').toLowerCase();
      wordRow[2] = (wordRow[2] ?? '').toLowerCase();
      wordRow[3] = (wordRow[3] ?? '').toLowerCase();
      for (let bc of breadcrumbs) {
        bc = bc.toLowerCase();
        if (!wordRow.includes(bc)) {
          return false;
        }
      }
      return wordRow;
    });
    // (You may want to do something with filteredWords here)
  };

  const [categories, setCategories] = useState() as any;
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [displayableTableWords, setDisplableTableWords] = useState([]) as any;
  const [words, setWords] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMobile, isTablet, isDesktop } = useResponsive()
  const setLoading = useStore((state) => state.setLoading);

  const filterWords = () => {
    const filteredWords = words.filter((wordRow: any) => {
      wordRow[1] = (wordRow[1] ?? '').toLowerCase();
      wordRow[2] = (wordRow[2] ?? '').toLowerCase();
      wordRow[3] = (wordRow[3] ?? '').toLowerCase();
      for (let bc of breadcrumbs) {
        bc = bc.toLowerCase();
        if (!wordRow.includes(bc)) {
          return false;
        }
      }
      return wordRow;
    });
    return filteredWords;
    setDisplayableWords(filteredWords);
  };

  useEffect(() => {
    setLoading(true);
    console.log({ breadcrumbs });
    console.log({ loadedCategoryIds });
    if (loadedCategoryIds.has(breadcrumbs.join(','))) {
      setDisplayableWords(breadcrumbs);
      setLoading(false);
      return;
    }

    getWordsByCategory(
      breadcrumbs,
      updateWords,
      loadedIds,
      addLoadedCategoryIds
    ).then(() => {
      console.log({ loadedIds });
      setDisplayableWords(breadcrumbs);
      setLoading(false);
    });
  }, [breadcrumbs]);

  const capitalize = (s: string) => {
    if (!s) return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getJLPT = (s: string) => {
    if (!s || s === '') return 'N1+';
    return capitalize(s.slice(-2));
  };

  const getCategoryLabel = (key: string) => {
    return (
      <>
        <AllButton
          ghost
          type="primary"
          size="small"
          onClick={(e) => e.stopPropagation()}
        >
          All
        </AllButton>
        <MenuItemLabelText>{key}</MenuItemLabelText>
      </>
    );
  };

  const getItems = () => {
    const topCats = c1.map((cat1: Category) => {
      const { category, sub_categories } = cat1;
      const children = sub_categories.map((cat2name: string) => {
        const foundCat = c2.find((c2cat: Category) => {
          return c2cat.category === cat2name;
        });
        const children = foundCat?.sub_categories.map((c3: string) => {
          return {
            key: c3,
            label: c3,
          };
        });
        return {
          key: foundCat?.category,
          label: foundCat?.category,
          children: [
            {
              key: `All ${foundCat?.category}`,
              label: `All ${foundCat?.category}`,
            },
            ...(children ?? []),
          ],
        };
      });
      return {
        key: category,
        label: category,
        children: [
          {
            key: `All ${category}`,
            label: `All ${category}`,
            bc: [],
          },
          ...children,
        ],
      };
    });
    return topCats;
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Responsive breakpoint hook from antd
  const screens = useBreakpoint();

  // Render the Menu component; mode is horizontal for desktop, vertical for mobile Drawer
  const renderMenu = () => (
    <Menu
      theme="dark"
      mode={screens.md ? 'horizontal' : 'vertical'}
      defaultSelectedKeys={['Home']}
      onClick={onMenuClick}
      items={items1}
      style={{ minWidth: 0 }}
    />
  );

  return (
    <Layout style={{ padding: 0, overflowX: 'hidden', height: '100vh' }}>
      <InfoModal />
      
      {/* {renderContent()} */}
      <CategoryPage />
    </Layout>
  );
};

export default Home;
