import React, { useState } from "react";
import { Layout, Switch } from "antd";
import PhotoUpload from "../organism/photoUpload"; // your current component
import DrawingInput from "../organism/drawing"; // new drawing component
import SiteBreadcrumb from "../molecule/siteBreadcrumb";
import { Content, Footer } from "antd/es/layout/layout";
import { useResponsive } from "../../utils/useResponsive";
import RecognizedCharacter from "../molecule/recognizedCharacter";

const CharacterRecognition = () => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [recognizedCharacter, setRecognizedCharacter] = useState("");
    const { isMobile } = useResponsive();
  
    const handleRecognizedCharacter = (char: string) => {
      setRecognizedCharacter(char);
    };


  return (
    <Layout style={{ height: "100vh" }}>
      <Content style={{ padding: isMobile ? "0" :"0 48px" }}>
       {!isMobile && <SiteBreadcrumb />}
        {/* Switch to toggle input modes */}
        
        <div
          style={{
            background: "#fff",
            minHeight: 280,
            padding: 24,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
             <div style={{marginBottom: 14}}>Currently only trained and recognized on <a href="https://en.wikipedia.org/wiki/Katakana">46 modern katakana</a>  with no functional marks
             and diacritics </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
             
                <div>Photo</div>
                <Switch
                checkedChildren=""
                unCheckedChildren=""
                checked={isDrawing}
                onChange={(checked) => setIsDrawing(checked)}
                style={{ marginBottom: 16, marginLeft: 16, marginRight: 16 }}
                />
                <div>Drawing</div>
            </div>
            <RecognizedCharacter  character={recognizedCharacter} />
          {isDrawing ? <DrawingInput onRecognizeCharacter={handleRecognizedCharacter}  /> : <PhotoUpload onRecognizeCharacter={handleRecognizedCharacter}  />}
        </div>
      </Content>
      {!isMobile && <Footer style={{ textAlign: "center" }}>
        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </Footer>}
    </Layout>
  );
};

export default CharacterRecognition;