// photoUpload.tsx
import React, { useState } from "react";
import { Upload, message, Image, Progress } from "antd";
import type { UploadProps } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import { InboxOutlined } from "@ant-design/icons";
import { config } from "../../consts/url";

const { Dragger } = Upload;

interface CustomUploadProps extends UploadProps {
  capture?: boolean | "user" | "environment";
}

interface PhotoUploadProps {
  onRecognizeCharacter: (char: string) => void;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({ onRecognizeCharacter }) => {
  const [ocrResult, setOcrResult] = useState<string>("");
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const uploadUrl = `${config.url}/api/upload`;

  const props: CustomUploadProps = {
    name: "file",
    action: uploadUrl,
    headers: {},
    accept: "image/*",
    capture: "user",
    beforeUpload: (file: RcFile) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    },
    onChange(info) {
      const { status, response, originFileObj } = info.file;

      if (status === "uploading") {
        setUploadProgress(Math.round(info.file.percent || 0));
      }

      if (status === "done") {
        setUploadProgress(100);
        const recognized = response.text || "";
        setOcrResult(recognized);
        onRecognizeCharacter(recognized);
        if (originFileObj) {
          const reader = new FileReader();
          reader.readAsDataURL(originFileObj);
          reader.onload = () => {
            setPreviewUrl(reader.result as string);
          };
        }
        message.success(`${info.file.name} file uploaded successfully.`);
        setTimeout(() => {
          setUploadProgress(0);
        }, 2000);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploadProgress(0);
      }
    },
    showUploadList: false,
  };

  return (
    <div
      style={{
        background: "#fff",
        minHeight: 280,
        padding: 24,
        borderRadius: 8,
      }}
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click, drag, or tap to upload a photo</p>
        <p className="ant-upload-hint">
          Supports a single image upload. On mobile, you can also take a new picture.
        </p>
      </Dragger>
      {uploadProgress > 0 && (
        <div style={{ marginTop: 16 }}>
          <Progress percent={uploadProgress} />
        </div>
      )}
      {previewUrl && (
        <div style={{ marginTop: 24 }}>
          <h3>Image Preview</h3>
          <Image src={previewUrl} alt="Uploaded preview" style={{ maxWidth: 150 }} />
        </div>
      )}

    </div>
  );
};

export default PhotoUpload;