import React, { FC, useState } from "react";
import { Breadcrumb, Button, Card, List, Modal, Tag } from "antd";
import styled from "styled-components";

const SiteBreadcrumb: FC = (props) => {
  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      <Breadcrumb.Item>List</Breadcrumb.Item>
      <Breadcrumb.Item>App</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default SiteBreadcrumb;
