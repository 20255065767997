// src/services/uploadService.ts
import { config } from "../../consts/url";

export async function uploadImage(dataUrl: string): Promise<any> {
  const formData = new FormData();
  // Convert data URL to Blob
  const blob = dataURLtoBlob(dataUrl);
  formData.append("file", blob, "image.png");

  const response = await fetch(`${config.url}/api/upload`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Upload failed");
  }

  return response.json();
}

function dataURLtoBlob(dataurl: string): Blob {
  const [header, base64Data] = dataurl.split(",");
  const mimeMatch = header.match(/:(.*?);/);
  if (!mimeMatch) {
    throw new Error("Invalid data URL");
  }
  const mime = mimeMatch[1];
  const bstr = atob(base64Data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}