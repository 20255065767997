import React from 'react';
import { Button, Result } from 'antd';

const NotFoundPage: React.FC = () => (
  <Result
    status="404"
    title="404 Bruh."
    subTitle="I haven't made this page (yet?)"
    extra={<Button 
    
      type="primary"
        onClick={() => {
          window.location.href = "https://wordwaku.com";
        }}
    >Back Home</Button>}
  />
);

export default NotFoundPage;