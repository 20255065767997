import {create} from "zustand"
import { WordDocument } from "../../consts/wordDocument.type"
import { Category } from "../../consts/category.type"

type CounterStore = {
    count: number
}

type WordsStore = {
    words: Array<WordDocument>;
    setWords: (newWords: Array<WordDocument>)=> any
}

type CategoriesStore = {
    test: Array<any>,
    category: Array<Category>,
    setCategory: (category: Array<Category>)=>any
}

type LoadedIdStore = {
    loadedIds: Array<string>,
    setLoadedIds: (newIds: Array<string>)=>any
}

type Store = {
    
    loadedCategoryIds: Set<string>;
    addLoadedCategoryIds: (ids: Array<string>) => void;
    // fetchWordsForCategory: (categoryId: string) => Promise<void>;


    // WINDOW
    window: number;
    setWindow: (num: number)=>any;

    // CATEGORIES_1
    categories_1: Array<Category>;
    setCategories_1: (categories: Array<Category>)=>any;

    // CATEGORIES_2
    categories_2: Array<Category>;
    setCategories_2: (categories: Array<Category>)=>any;

    // FILTERS
    breadcrumbs: Array<string>;
    setBreadcrumbs: (bc: Array<string>)=>any;

    // WORDS
    words: Array<WordDocument>;
    setWords: (words: Array<WordDocument>)=>any;
    updateWords: (newWords: Array<WordDocument>)=>any;
    loadedIds: Array<string>;
    updateLoadedIds: (newIds: Array<string>)=>any;

    // DISPLAYED WORDS
    displayedWords: Array<WordDocument>;
    setDisplayedWords: (breadcrumbs: any)=>any;

    // MODAL
    
    selectedWord: WordDocument,
    setSelectedWord: any,
    isInfoModalOpen: boolean,
    setIsInfoModalOpen: any

    // Loading
    loading: boolean;
    setLoading: (breadcrumbs: any)=>any;


}

export const useStore = create<Store>((set)=>({
        loadedCategoryIds: new Set(),
        addLoadedCategoryIds: (ids) => set((state) => {
            console.log({ids})
            const updatedIds = new Set(state.loadedCategoryIds);
            updatedIds.add(ids.join(","));
            return { loadedCategoryIds: updatedIds };
        }),

        // add fetch function directly to store (recommended)
        // fetchCategoryData: async (categoryId: string) => {
        //     const { loadedCategoryIds, addLoadedCategoryId, updateWords, setLoading } = get();

        //     if (loadedCategoryIds.includes(categoryId)) {
        //         console.log("✅ Already loaded category, skipping API call.");
        //         return;
        //     }

        //     set({ loading: true });

        //     try {
        //         const response = await axios.get(`${config.url}/api/categories/${categoryId}`);
        //         updateWords(response.data.words);  // you already have this
        //         addLoadedCategoryId(categoryId);
        //     } catch (error) {
        //         console.error(error);
        //     } finally {
        //         set({ loading: false });
        //     }
        // },


        // WINDOW
        window: 1920,
        setWindow: (window: number)=>window,

        // CATEGORIES_1
        categories_1: [],
        setCategories_1: (categories: Array<Category>)=>set((state)=>({categories_1: categories})),
        
        // CATEGORIES_2
        categories_2: [],
        setCategories_2: (categories: Array<Category>)=>set((state)=>({categories_2: categories})),

        // Filters
        breadcrumbs: [],
        setBreadcrumbs:  (bc: Array<string>)=>set((state)=>({breadcrumbs: bc})),

        // WORDS
        words: [],
        setWords: (words: Array<WordDocument>)=>set((state)=>({words})),
        updateWords: (newWords: Array<WordDocument>)=>{
            set((state)=>{
                const newWordIds = newWords.map((newWord)=>newWord._id)
                state.updateLoadedIds(newWordIds)
                return ({words: [...state.words, ...newWords]})
            })
        },
        loadedIds: [],
        updateLoadedIds: (newIds: Array<string> = [])=>{
            set((state)=>{
                // state.loadedIds
                return ({loadedIds:[...state.loadedIds, ...newIds.filter((id:string)=>!state.loadedIds.includes(id))]})
            })
        },
        displayedWords: [],
        setDisplayedWords: (breadcrumbs: Array<string>)=>set((state)=>{
            // WORDS: ONLY IF WORD.CATEGORIES HAS ALL BREADCRUMBS
            let displayedWords = state.words.filter((word: WordDocument)=>{
                let hasBreadcrumbs = true;
                for(let bc of breadcrumbs) {
                    if(!word.categories.includes(bc)) {
                        hasBreadcrumbs = false;
                        break;
                    }
                }
                if(hasBreadcrumbs) return word
            })
            return ({displayedWords: displayedWords})}
        ),


        // MODAL
        selectedWord: {_id: '', word: '', sentences: [], categories: [], kanji: [], word_data: {is_common: false, jlpt: [], tags: [], japanese: "", reading: "", english_definitions: [], antonyms: [], parts_of_speech: []}},
        setSelectedWord: (selectedWord: WordDocument)=>set((state)=>({selectedWord})),

        isInfoModalOpen: false,
        setIsInfoModalOpen: (isInfoModalOpen: boolean)=>set((state)=>({isInfoModalOpen})),


        // LOADING
        loading: true,
        setLoading: (loading: boolean)=>set((state)=>({loading})),
    }
))

export const useCounterStore = create<CounterStore>(()=>({
    count: 34
}))

export const useLoadedIdStore = create<LoadedIdStore>((set)=>({
    loadedIds: [],
    setLoadedIds: (ids: Array<string>)=>set((state)=>({loadedIds: ids}))
}))

export const useWordsStore = create<WordsStore>((set)=>({
    words: [],
    setWords: (newWords: Array<WordDocument>)=>set((state)=>({words: [...state.words, ...newWords]})),

}))

export const useC1Store = create<CategoriesStore>((set)=>({
    test: [],
    category: [],
    setCategory: (category: Array<Category>)=>set((state)=>({category: [...category]}))
    }
))

export const useC2Store = create<CategoriesStore>((set)=>({
    test: [],
    category: [],
    setCategory: (category: Array<Category>)=>set((state)=>({category: [...category]}))
    }
))