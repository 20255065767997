import React, { useEffect, useState } from 'react';

import './App.css';
import { useC1Store, useC2Store, useStore } from './state/stores/store';
import Home from './components/page/home';
import { getC1, getC2 } from './api/axios/categories';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLayout from './components/wrappers/AppLayout';
import CharacterRecognition from './components/page/characterRecognition';
import SearchKanji from './components/organism/searchKanji';
import CategoryPage from './components/page/categoryPage';
import Lookup from './components/page/lookup';
import NotFoundPage from './components/page/notFoundPage';

function App() {
  const updateC1 = useC1Store((state) => state.setCategory);
  const updateC2 = useC2Store((state) => state.setCategory);
  const c1 = useC1Store((state) => state.category);
  const c2 = useC2Store((state) => state.category);


  const setC1 = async () => {
    const c1 = await getC1();
    updateC1(c1);
  };

  const setC2 = async () => {
    const c2 = await getC2();
    updateC2(c2);
  };

  useEffect(() => {
    if (c1.length < 1) {
      setC1();
    }
    if (c2.length < 1) {
      setC2();
    }
  });

  return (

    <BrowserRouter>
    <Routes>
      {/* Layout wrapper for pages that share the common UI */}
      <Route element={<AppLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/character-recognition" element={<CharacterRecognition />} />

        <Route path="/kanji-search" element={<Lookup />} />



        
      </Route>
      {/* Optional fallback */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
