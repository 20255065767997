// const production = {
//     url: 'https://wordwaku-backend.onrender.com'
// }

// const development = {
//     url: 'http://localhost:4000'
// }

// export const config = process.env.NODE_ENV === 'development' ? development : production 

const production = {
    url: (process.env.REACT_APP_BACKEND_URL || 'https://backend.up.railway.app').replace(/\/+$/, '')
}


const development = {
    url: 'http://localhost:4000'
}

export const config = process.env.NODE_ENV === 'development' ? development : production;
