import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Layout, Menu, Breadcrumb, theme } from 'antd';
import { useC1Store, useC2Store, useStore } from '../../state/stores/store';
import VocabList from '../organism/vocabList';
import SiteBreadcrumb from '../molecule/siteBreadcrumb';
import type { Category } from '../../consts/category.type';

import { useResponsive } from '../../utils/useResponsive';

const { Content, Sider } = Layout;

const CategoryPage: React.FC = () => {
  // const [isMobile, setIsMobile] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const breadcrumbs = useStore((state) => state.breadcrumbs);
  const setBreadcrumbs = useStore((state) => state.setBreadcrumbs);
  const c1 = useC1Store((state) => state.category);
  const c2 = useC2Store((state) => state.category);

  const { isMobile, isTablet, isDesktop } = useResponsive()

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Memoize the computed menu items to avoid unnecessary re-calculations.
  const menuItems = useMemo(() => {
    return c1
    .filter((cat1: Category) => cat1.category !== "Occupation") // FILTER OUT FIRST
    .map((cat1: Category) => {
      const { category, sub_categories } = cat1;
      const children = sub_categories.map((cat2name: string) => {
        const foundCat = c2.find(
          (c2cat: Category) => c2cat.category === cat2name
        );
        const subItems = foundCat?.sub_categories.map((c3: string) => ({
          key: c3,
          label: c3,
        }));
        return {
          key: foundCat?.category,
          label: foundCat?.category,
          children: [
            {
              key: `All ${foundCat?.category}`,
              label: `All ${foundCat?.category}`,
            },
            ...(subItems ?? []),
          ],
        };
      });
      return {
        key: category,
        label: category,
        children: [
          {
            key: `All ${category}`,
            label: `All ${category}`,
          },
          ...children,
        ],
      };
    });
  }, [c1, c2]);

  // Handler for menu item selection.
  const handleMenuSelect = useCallback(
    (e: any) => {
      const revKeyPath = e.keyPath.reverse();
      let newBreadcrumbs = e.key.startsWith('All')
        ? revKeyPath.slice(0, revKeyPath.length === 2 ? 1 : 2)
        : revKeyPath;
      newBreadcrumbs = newBreadcrumbs.map((item: string) => item.toLowerCase());
      setBreadcrumbs(newBreadcrumbs);
      console.log({isMobile, isDesktop})
      if (!isDesktop) {
        setSiderCollapsed(true);
      }
    },
    [setBreadcrumbs]
  );

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsible
        collapsedWidth={isMobile ? '0' : 50}
        onCollapse={(collapsed) => setSiderCollapsed(collapsed)} // <-- ADD THIS LINE
        collapsed={siderCollapsed}
        // onBreakpoint={setIsMobile}
        onBreakpoint={(broken) => {
          // setIsMobile(broken);
          if (broken) {
            setSiderCollapsed(true); // Auto-collapse on mobile
          }
        }}
        width={300}
        style={{ background: colorBgContainer }}
      >
        <Menu
          mode="inline"
          style={{ height: '50px' }}
          items={menuItems}
          onSelect={handleMenuSelect}
        />
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && <SiteBreadcrumb />}
          <Breadcrumb
            style={{ margin: '16px 0 16px 15px' }}
            items={breadcrumbs.map((bc: string) => ({ title: bc }))}
          />
        </div>
        <Content
          style={{
            padding: isMobile ? 0 : 24,
            margin: 0,
            minHeight: 300,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <VocabList />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CategoryPage;
