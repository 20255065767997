//  TO DO: DELETE AND REFACTOR WITH lookup.tsx


import React, { FC, useEffect, useState } from "react";
import { Card, Layout, Table, Tag } from "antd";
import type { Breakpoint, TableProps } from "antd";
import styled from "styled-components";
import { useStore, useWordsStore } from "../../state/stores/store";
import { WordDocument } from "../../consts/wordDocument.type";
import { capitalizeFirstLetter, formatJlptList } from "../../utils/stringUtil";
import { Breadcrumb, Menu, theme } from "antd";
import Lookup from "../page/lookup";
import SiteBreadcrumb from "../molecule/siteBreadcrumb";

const items = Array.from({ length: 15 }).map((_, index) => ({
  key: index + 1,
  label: `nav ${index + 1}`,
}));
const { Header, Content, Footer } = Layout;

type ColumnsType<T extends object> = TableProps<T>["columns"];
type TablePagination<T extends object> = NonNullable<
  Exclude<TableProps<T>["pagination"], boolean>
>;
type TablePaginationPosition = NonNullable<
  TablePagination<any>["position"]
>[number];

interface SearchKanjiProps {}

export interface DataType {
  key: string;
  kanji: string;
  reading: string;
  english: string;
  jlpt: string;
  tags?: string[];
}

const capitalize = (s: string) => {
  if (!s) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const renderEng = (eng: string) => {
  return eng
    .replace(/[\[\]']/g, "")
    .split(", ")
    .map((word) => capitalize(word))
    .join(", ");
};

interface KanjiFontProps {
  width?: number;
}

const KanjiFont = styled.span<KanjiFontProps>`
  font-size: ${(props) => (props.width && props.width > 600 ? "20px" : "15px")};
`;

const getTags = (isCommon = false, categories: Array<string>) => {
  let tags = categories.map((category: string) => category);
  if (isCommon) tags.unshift("Common");
  return tags;
};

const SearchKanji: FC<SearchKanjiProps> = (props: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const setIsInfoModalOpen = useStore((state) => state.setIsInfoModalOpen);
  const setSelectedWord = useStore((state) => state.setSelectedWord);
  const loading = useStore((state) => state.loading);

  const {} = props;

  const words = useStore((state) => state.displayedWords);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [top, setTop] = useState<TablePaginationPosition>("topLeft");
  const [bottom, setBottom] = useState<TablePaginationPosition>("bottomRight");
  const [openedRowData, setOpenRowData] = useState({});

  const breakpoint: Breakpoint = "lg";

  const columns = [
    {
      title: "Kanji",
      dataIndex: "kanji",
      key: "kanji",
      width: "8%",
      render: (text: string) => <KanjiFont>{text}</KanjiFont>,
      // responsive: ["sm"],
    },
    {
      title: "Reading",
      dataIndex: "reading",
      key: "reading",
      width: "8%",
    },
    {
      title: "Translations",
      dataIndex: "english_definitions",
      key: "english_definitions",
      width: "15%",
      render: (arr: Array<string>) => {
        return arr
          .map((sentence: string) => capitalizeFirstLetter(sentence))
          .join(", ");
      },
    },
    {
      title: "JLPT",
      dataIndex: "jlpt",
      key: "jlpt",
      render: (arr: Array<string>) => {
        return formatJlptList(arr);
      },
      filters: [
        { text: "N5", value: "N5" },
        { text: "N4", value: "N4" },
        { text: "N3", value: "N3" },
        { text: "N2", value: "N2" },
        { text: "N1", value: "N1" },
        { text: "N1+", value: "N1+" },
      ],
      //   onFilter: (value: any, record: any) => {
      //     console.log({ value, record });
      //     return record.jlpt[0] === value;
      //   },
      // responsive: "lg",
      width: "1%",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      responsive: [breakpoint],
      //   responsive: ["lg"],
      render: (tags: any) => (
        <span>
          {(tags ?? []).map((tag: any) => {
            if (tag === "NONE" || tag === "False") return <></>;
            if (tag === "Common")
              return (
                <Tag key={tag} color="green">
                  COMMON
                </Tag>
              );
            return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
          })}
        </span>
      ),
    },
  ];

  const getData = () => {
    return words.map((wordDoc: WordDocument) => {
      const { categories, word, word_data } = wordDoc;
      // console.log({ wordDoc });
      const { english_definitions, is_common, jlpt, reading, tags } = word_data;
      return {
        kanji: word,
        reading,
        english_definitions,
        jlpt,
        tags: getTags(is_common, categories),
        wordDoc,
      };
    });
  };

  return <Lookup />

  return (
    // <Layout.Content style={{ padding: 0 }}>
    //   <Table
    //     loading={loading}
    //     onRow={(record, rowIndex) => {
    //       return {
    //         onClick: (event) => {
    //           setIsInfoModalOpen(true);
    //           setSelectedWord(record.wordDoc);
    //           // console.log({ record });
    //         }, // click row
    //         onDoubleClick: (event) => {}, // double click row
    //         onContextMenu: (event) => {}, // right button click row
    //         onMouseEnter: (event) => {}, // mouse enter row
    //         onMouseLeave: (event) => {}, // mouse leave row
    //       };
    //     }}
    //     dataSource={getData()}
    //     columns={columns}
    //   />
    // </Layout.Content>
    <Layout style={{ height: "100vh" }}>
      {/* <Header style={{ display: "flex", alignItems: "center" }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header> */}
      <Content style={{ padding: "0 48px" }}>
        {/* <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <SiteBreadcrumb />
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
          }}
        >
          <Lookup />
        </div>
      </Content>
    </Layout>
  );
};

export default SearchKanji;
