import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

// https://dashboard.render.com/
// https://dashboard.render.com/

// https://railway.com/project/e3035394-a22e-41cb-8a8a-4af7bf50d2a1?environmentId=5a7587ee-5a34-4678-bf69-2eb7285d1c15
// https://railway.com/project/e3035394-a22e-41cb-8a8a-4af7bf50d2a1?environmentId=5a7587ee-5a34-4678-bf69-2eb7285d1c15

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
