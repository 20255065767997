import React from 'react';
import { Card, Space } from 'antd';


interface RecognizedCharacterProps{
  character?: string;
}

const RecognizedCharacter: React.FC<RecognizedCharacterProps> = (props) => {
    const {character = ""} = props
    
    return (
  <Space direction="vertical" size={16} style={{marginBottom: 16}}>
    <Card size="small" title={`Recognized Character: ${character}`} style={{ width: 300 }}>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 24, marginBottom: 24, fontSize: character ? 50 : 14, height: 75}}>
            {character ? character : "   Upload or draw an image."}
        </div>
    </Card>
  </Space>
)}

export default RecognizedCharacter;