// AppLayout.tsx
import React, { useState } from 'react';
import { Layout, Menu, Drawer, Button, Grid } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link, Outlet } from 'react-router-dom';
import { useResponsive } from '../../utils/useResponsive';

const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

const Logo = styled.span`
  font-family: 'Seymour One', sans-serif;
  font-style: bold;
  font-size: 40px;
`;

const menuItems = [
  { key: "home", label: <Link to="/">Home</Link> },
  { key: "kanjiSearch", label: <Link to="/kanji-search">Kanji Lookup</Link> },
  { key: "characterRecognition", label: <Link to="/character-recognition">Handwriting Analyzer</Link> },
];

const AppLayout: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { isMobile , isTablet, isDesktop,} = useResponsive();
  const screens = useBreakpoint();

  const renderMenu = () => (
    <Menu
      theme="dark"
      mode={screens.md ? 'horizontal' : 'vertical'}
      defaultSelectedKeys={['home']}
      items={menuItems}
      style={{ minWidth: 0 }}
    />
  );

  return (
    <Layout style={{ minHeight: '100vh', overflowX: 'hidden' }}>
      <Header style={{ display: 'flex', alignItems: 'center', padding: '0 16px' }}>
        {screens.md ? (
          renderMenu()
        ) : (
          <>
            <Button type="primary" icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} />
            <Drawer
              title="Navigation"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              bodyStyle={{ padding: 0 }}
              style={{ overflowX: 'hidden' }}
            >
              {renderMenu()}
            </Drawer>
          </>
        )}
        <Logo style={{ fontSize: 12, color: 'white', marginLeft: screens.md ? 16 : 8 }}>
          wordwaku - development
        </Logo>
      </Header>
      <Content style={{ padding: 0 }}>
        {/* Nested routes render here */}
        <Outlet />
      </Content>
      {!isMobile && <Footer style={{ textAlign: 'center' }}>
        WordWaku ©{new Date().getFullYear()} Created by Yokai
      </Footer>}
    </Layout>
  );
};

export default AppLayout;