import axios from "axios";
import { config } from "../../consts/url";

export const getWordsLimited = () => {
    return axios({
        // Endpoint to send files
        url: `${config.url}/api/words/all`,
        // url: "https://wordwaku-backend.onrender.com/api/words/all",
        withCredentials: false,
        method: "GET",
      })
        // Handle the response from backend here
        .then((res) => {
          console.log({ res });
          return res.data.words
        })
  
        // Catch errors if any
        .catch((err) => {
            console.log({err})
        });
}

export const getWordsByCategory = (categories, updateWords, ignoredIds = [], addLoadedCategoryIds) => {
  return axios({
    // Endpoint to send files
    url: `${config.url}/api/words/getWordsByCategories`,
    // url: "https://wordwaku-backend.onrender.com/api/words/getWordsByCategories",
    withCredentials: false,
    method: "POST",
    data: {
      categories: categories,
      ignoredIds: ignoredIds
    }
  })
    // Handle the response from backend here
    .then((res) => {
      console.log({res})
      updateWords(res.data.words)
      console.log(res.data.words)
      
      addLoadedCategoryIds(categories)
      console.log({categories})
      return res.data.words
    })

    // Catch errors if any
    .catch((err) => {
        console.log({err})
    });
}