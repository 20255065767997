const capitalizeFirstLetter = (s:string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const JLPTs = ["N5", "N4", "N3", "N2", "N1", "N1+"]

const formatJlptList = (jlpt: Array<string>) => {
    if(jlpt.length == 0) return "N1+"
    const formattedJLPT =  jlpt.map((word)=>{
        return formatJlptWord(word)
     }).join("")
    for(const i of JLPTs) {
        if(formattedJLPT.includes(i)){
            return i
        }
    }
}

const formatJlptWord = (s: string) => {
    return s.slice(s.length-2, s.length).toLocaleUpperCase()
}

export {capitalizeFirstLetter, formatJlptList, formatJlptWord} 