import React, { useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { Button , message} from "antd";
import { useResponsive } from "../../utils/useResponsive";
import { uploadImage } from "../../api/services/uploadService";


interface DrawingInputProps {
    onRecognizeCharacter: (char: string) => void;
  }

  const DrawingInput: React.FC<DrawingInputProps> = ({ onRecognizeCharacter }) => {
    const canvasRef = useRef<any>(null);
      const {isMobile, isTablet, isDesktop} = useResponsive()
      const [previewUrl, setPreviewUrl] = useState<string>("");
    
      const convertCanvasToJpegWithWhiteBg = (sourceCanvas: HTMLCanvasElement): string => {
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = sourceCanvas.width;
        tempCanvas.height = sourceCanvas.height;
        const ctx = tempCanvas.getContext("2d");
        if (ctx) {
          // Fill with white background.
          ctx.fillStyle = "#ffffff";
          ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
          // Draw the source canvas onto the temp canvas.
          ctx.drawImage(sourceCanvas, 0, 0);
        }
        // Return the JPEG data URL.
        return tempCanvas.toDataURL("image/jpeg", 1.0);
      };

      // Clear only the canvas without resetting the preview image.
      const clearCanvas = () => {
        if (canvasRef.current) {
          canvasRef.current.clear();
        }
      };
    
      // Clear both the canvas and the preview.
      const handleClear = () => {
        clearCanvas();
        setPreviewUrl("");
      };
    
      const handleSave = async () => {
        if (canvasRef.current) {
          // react-canvas-draw renders two canvases; the drawing is on the second one.
          const canvasContainer = canvasRef.current.canvasContainer;
          const drawingCanvas = canvasContainer.children[1] as HTMLCanvasElement;
        //   const dataUrl = drawingCanvas.toDataURL("image/png");

            const dataUrl = convertCanvasToJpegWithWhiteBg(drawingCanvas);
            // console.log(dataUrl)
          // Set the preview image.
          setPreviewUrl(dataUrl);
    
          try {
            const response = await uploadImage(dataUrl);
            console.log("Drawing uploaded:", response);
            message.success("Drawing uploaded successfully!");


            const recognized = response.text || "";
            // For now, we simulate with a placeholder:
            // const recognized = "あ"; // Replace with your actual result
            onRecognizeCharacter(recognized);
          } catch (error) {
            console.error(error);
            message.error("Failed to upload drawing.");
          }
          // Clear the drawing canvas (leaving the preview intact).
          clearCanvas();
        }
      };

    // const handleSave = async () => {
    //     if (canvasRef.current) {
    //       const canvasContainer = canvasRef.current.canvasContainer;
    //       const drawingCanvas = canvasContainer.children[1] as HTMLCanvasElement;
    //       // Convert the drawing canvas to JPEG with a white background if needed.
    //       const dataUrl = drawingCanvas.toDataURL("image/png");
    //       console.log(dataUrl);
    //       setPreviewUrl(dataUrl);
    
    //       try {
    //         // Here you might upload the drawing and get a recognized character.
    //         // For example:
    //         // const response = await uploadImage(dataUrl);
    //         // const recognized = response.text || "";
    //         // For now, we simulate with a placeholder:
    //         const recognized = "あ"; // Replace with your actual result
    //         onRecognizeCharacter(recognized);
    //         message.success("Drawing uploaded successfully!");
    //       } catch (error) {
    //         console.error(error);
    //         message.error("Failed to upload drawing.");
    //       }
    //       clearCanvas();
    //     }
    //   };

  return (
    <div>
      <CanvasDraw
        ref={canvasRef}
        brushColor="#000"
        brushRadius={2}

        catenaryColor="transparent"
        canvasWidth={isMobile ? 350 : 400}
        canvasHeight={isMobile ? 350 : 400}
        style={{border: 'solid 2px black'}}
      />
      <div style={{ marginTop: 16 }}>
        <Button onClick={handleClear} style={{ marginRight: 8 }}>
          Clear
        </Button>
        <Button type="primary" onClick={handleSave}>
          Save Drawing
        </Button>
      </div>
    </div>
  );
};

export default DrawingInput;